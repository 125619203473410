@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Work Sans', sans-serif;
}
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

.prose p,
    .prose ol,
    .prose li,
    .prose ul,
    .prose h1,
    .prose h2,
    .prose h3,
    .prose h4,
    .prose h5,
    .prose h6 {
        margin: 0px !important;
    }

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.app-container{
  margin-top: 70px;
  min-height: calc(100vh - 100px);
}
svg{
  cursor: pointer;
}
input:disabled{
  opacity: 0.7;
  background-color: #CFD8DC;
}

.c-height{
  min-height: calc(100vh - 160px);
}
.chat-height{
  min-height: calc(100vh - 230px);
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
}

.modal-open {
  opacity: 0;
  animation: modalFadeIn 0.3s forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-close {
  opacity: 1;
  animation: modalFadeOut 0.3s forwards;
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.app-main{
  max-width: 2500px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media screen and (max-width: 600px) {
  .chat-height {
    min-height: calc(100vh - 205px);
    max-height: calc(100vh - 205px);
    overflow-y: scroll;
  }
}